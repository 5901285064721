<template>
  <fragment>
    <slot :data="parentescos" :loading="loading"></slot>
  </fragment>
</template>

<script>
import { mapState } from "vuex";
import catalogoService from "@/services/catalogoService";

export default {
  name: "AsyncParentesco",
  data() {
    return {
      loading: false,
      parentescos: [],
    };
  },
  computed: {
    ...mapState({
      dadosTitularState: (state) => state.cadastroProposta.titular,
      vigenciaState: (state) => state.cadastroProposta.vigencia,
      plano: (state) => state.cadastroProposta.plano,
      vigenciaPME: (state) => state.PME.vigencia,
    }),
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await catalogoService.getGrauParentesco({
          idProdutoFatura: this.plano.idProdutoFatura,
          estadoCivil: this.dadosTitularState.estadoCivil,
          dataVigencia: this.vigenciaState && this.vigenciaState.dataVigencia || this.vigenciaPME,
          publicoAlvoTitular: this.dadosTitularState && this.dadosTitularState.publicoAlvo,
          dataNascimentoTitular: this.dadosTitularState && this.dadosTitularState.dataNascimento,
        });
        this.parentescos = data;
        this.$emit("success", data.data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar dados, tente novamente mais tarde",
        });
        this.$emit("error");
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style>
</style>
