import api from "@/services/api";
import AppError from "@/utils/appError";

const resource = "lista-atencao";

const verificarBloqueiosListaAtencao = async ({ cpf, operadora }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_LISTA_ATENCAO } };
    const { data } = await api.get(`${resource}/clientes/${cpf}?operadora=${operadora}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  verificarBloqueiosListaAtencao,
};
